<template>
    <section id="footer-top">
        <div class="container-links" v-if="isMobile == false">
            <div class="container">
                <div class="contact-us-container footer-col ">
                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/DG-Colour-Dark-Footer.png"
                        alt="doo-group" class="logo">
                    <p class="contact-us-txt">{{ info?.contact_list?.[0]?.translate }}</p>
                    <div class="email-list">
                        <p v-for="item, index in getEmailList()" class="email">
                            <img :class="index == 0 ? '' : 'icon-hidden'"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Email.svg" />
                            {{ item.translate }}
                        </p>
                    </div>
                    <div class="hp-container">
                        <div class="wrapper-hp" v-for="item, index in getPhoneNumberList()">
                            <img :class="index == 0 ? '' : 'icon-hidden'"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Phone.svg" />
                            <p class="hp">{{ item[0] }}</p>
                            <p class="content">{{ item[1] }}</p>
                        </div>
                    </div>

                    <div class="social">
                        <p class="follow" v-t="'footer.follow-us'"></p>
                        <div class="social-logo">
                            <a v-for="item in info?.social_media.slice(1)" :href="item.link" target="_blank"
                                class="icon-wrapper">
                                <img :src="`https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/${item.translate}.svg`"
                                    :alt="item.translate" class="logo">
                                <img :src="`https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/${item.translate}-red.svg`"
                                    :alt="item.translate" class="logo">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="wrapper">
                    <div class="column">
                        <!--div class="container-links-contact footer-col">
                            <div class="list-content col-1 ">
                                <a href="/" v-t="'footer.brand'"></a>
                                <a href="/" v-t="'footer.overview'"></a>
                                <a href="/" v-t="'footer.brokerage'"></a>
                                <a href="/" v-t="'footer.wealth-management'"></a>
                                <a href="/" v-t="'footer.payment-exchange'"></a>
                                <a href="/" v-t="'footer.finance'"></a>
                            </div>
                        </div-->
                        <div class="container-links-contact footer-col">
                            <div class="list-content col-1 ">
                                <p v-t="'footer.about-us'"></p>
                                <RouterLink :to="`/${$i18n.locale}/groupIntroduction`"
                                    v-t="'navigation.group-introduction'"></RouterLink>
                                <RouterLink :to="`/${$i18n.locale}/milestone`" v-t="'navigation.milestone'">
                                </RouterLink>
                                <RouterLink :to="`/${$i18n.locale}/license`" v-t="'navigation.regulatory-licenses'">
                                </RouterLink>
                                <RouterLink :to="`/${$i18n.locale}/globalOffice`"
                                    v-t="'navigation.global-operational-centers'"></RouterLink>
                                <a href="https://career.doo.com/career/" v-t="'navigation.join-us'"></a>
                            </div>
                        </div>
                        <div class="container-links-contact footer-col">
                            <div class="list-content col-1 ">
                                <p>{{ info?.business_and_brand?.[0]?.translate }}</p>
                                <a v-for="(item) in info?.business_and_brand.slice(1)" :href="item.link">{{
                                    item.translate }}</a>
                            </div>
                        </div>
                        <div class="container-links-contact footer-col">
                            <div class="list-content col-1 ">
                                <p>{{ info?.partnerships?.[0]?.translate }}</p>
                                <a v-for="(item) in info?.partnerships.slice(1)" :href="item.link">{{ item.translate
                                    }}</a>
                            </div>
                        </div>
                        <div class="container-links-contact footer-col">
                            <div class="list-content col-1 ">
                                <p>{{ info?.latest_news?.[0]?.translate }}</p>
                                <a v-for="(item) in info?.latest_news.slice(1)" :href="item.link">{{ item.translate
                                    }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-links-mobile" v-else>
            <div class="wrapper-links">
                <div class="contact-us-container footer-col ">
                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/DG-Colour-Dark-Footer.png"
                        alt="doo-group" class="logo">
                    <p class="contact-us-txt">{{ info?.contact_list?.[0]?.translate }}</p>
                    <div class="email-list">
                        <p v-for="item, index in getEmailList()" class="email">
                            <img :class="index == 0 ? '' : 'icon-hidden'"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Email.svg" />
                            {{ item.translate }}
                        </p>
                    </div>

                    <div class="hp-container">
                        <div class="wrapper-hp" v-for="item, index in getPhoneNumberList()">
                            <img :class="index == 0 ? '' : 'icon-hidden'"
                                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Phone.svg" />
                            <p class="hp">{{ item[0] }}</p>
                            <p class="content">{{ item[1] }}</p>
                        </div>
                    </div>

                    <div class="social">
                        <p class="follow" v-t="'footer.follow-us'"></p>
                        <div class="social-logo">
                            <a href="https://www.linkedin.com/company/doo-holding-group-limited/" target="_blank">
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/LinkedIn.svg"
                                    alt="linkedIn" class="linkedIn logo">
                            </a>
                            <a href="https://www.facebook.com/DooGroup" target="_blank">
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Facebook.svg"
                                    alt="facebook" class="facebook logo">
                            </a>
                            <a href="https://www.instagram.com/doo_group/" target="_blank">
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Instagram.svg"
                                    alt="instagram" class="instagram logo">
                            </a>
                            <a href="https://www.youtube.com/@doogroup6728" target="_blank">
                                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/YouTube.svg"
                                    alt="youtube" class="youtube logo">
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="logos-mobile">
                <!-- doogroup -->
                <a v-for="item in info?.doo_product_list.slice(1)" :href="item.link" target="_blank"
                    class="icon-wrapper">
                    <img class="logo" :src="`${subBrandUrl}${item.translate}_off_mb.png`" :alt="item.translate">
                </a>
            </div>

            <div class="border" style="margin-top:0;"></div>

            <div class="container-links-contact footer-col">
                <div class="list-content col-1">
                    <div class="txt-wrapper" @click="toggleAboutUs">
                        <p class="title" v-t="'footer.about-us'"></p>
                        <img class="icon-dropdown"
                            src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Dropdown_dark.svg"
                            alt="dropdown">
                    </div>
                    <div class="container-dropdown" v-if="aboutUs">
                        <RouterLink :to="`/${$i18n.locale}/groupIntroduction`" v-t="'navigation.group-introduction'">
                        </RouterLink>
                        <RouterLink :to="`/${$i18n.locale}/milestone`" v-t="'navigation.milestone'"></RouterLink>
                        <RouterLink :to="`/${$i18n.locale}/license`" v-t="'navigation.regulatory-licenses'">
                        </RouterLink>
                        <RouterLink :to="`/${$i18n.locale}/globalOffice`" v-t="'navigation.global-operational-centers'">
                        </RouterLink>
                        <a href="https://career.doo.com/career/" v-t="'navigation.join-us'"></a>
                    </div>
                </div>
            </div>

            <div class="border"></div>

            <div class="container-links-contact footer-col">
                <div class="list-content col-1">
                    <div class="txt-wrapper" @click="toggleBusinessAndBrand">
                        <p class="title" v-t="'navigation.business-and-brand'"></p>
                        <img class="icon-dropdown"
                            src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Dropdown_dark.svg"
                            alt="dropdown">
                    </div>
                    <div class="container-dropdown" v-if="brandSection">
                        <a v-for="(item) in info?.business_and_brand.slice(1)" :href="item.link">{{ item.translate
                            }}</a>
                    </div>
                </div>
            </div>

            <div class="border"></div>

            <div class="container-links-contact footer-col">
                <div class="list-content col-2 ">
                    <div class="txt-wrapper" @click="togglePartnership">
                        <p class="title" v-t="'footer.partnership'"></p>
                        <img class="icon-dropdown"
                            src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Dropdown_dark.svg"
                            alt="dropdown">
                    </div>
                    <div class="container-dropdown" v-if="partnershipSection">
                        <!-- <a href="/" v-t="'footer.our-partners'"></a> -->
                        <a href="https://www.doo.com/en/partnership/manutd" v-t="'footer.Mu'"></a>
                        <a :href="`${locationOrigin}/${$i18n.locale}/partnership/aufc`" v-t="'footer.Au'"></a>
                    </div>
                </div>
            </div>
            <div class="border"></div>

            <div class="container-links-contact footer-col">
                <div class="list-content col-2 ">
                    <div class="txt-wrapper" @click="toggleNews">
                        <p class="title" v-t="'footer.news'"></p>
                        <img class="icon-dropdown"
                            src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Dropdown_dark.svg"
                            alt="dropdown">
                    </div>
                    <div class="container-dropdown" v-if="newsSection">
                        <a href="https://blog.doo.com/" v-t="'footer.media'"></a>
                    </div>
                </div>


            </div>
            <div class="border"></div>
            <div class="esg-single" style="padding-left:0;">
                <RouterLink :to="`/${$i18n.locale}/contactus`" v-t="'footer.contact-us'"></RouterLink>
            </div>

            <div class="border"></div>

        </div>
        <div class="logos" v-if="isMobile == false">
            <div class="container">
                <div class="wrapper">
                    <!--  # doo group -->
                    <div v-for="item in info?.doo_product_list.slice(1)" class="icon-wrapper">
                        <a :href="item.link" target="_blank" class="">
                            <img class="logo" :src="`${subBrandUrl}${item.translate}_off.png`" :alt="item.translate">
                            <img class="logo" :src="`${subBrandUrl}${item.translate}_on.png`" :alt="item.translate">
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script>

export default {
    props: {
        info: {},
    },
    setup() {
        return {
            subBrandUrl: 'https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/new-sub-brand/sub-brand_',
            emailRegex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        };
    },
    data() {
        return {
            isMobile: false,
            aboutUs: true,
            businessAndBrand: false,
            brandSection: false,
            partnershipSection: false,
            newsSection: false
        }
    },
    methods: {
        isEmail(txt) {
            return this.emailRegex.test(String(txt).toLowerCase())
        },
        getEmailList() {
            return this.info?.contact_list?.filter((item) => {
                if (this.isEmail(item.translate)) {
                    return item
                }
            })
        },
        getPhoneNumberList() {
            const regions = [];
            const numbers = [];
            const contactList = [];

            console.log('this.info?.contact_list', this.info)

            const list = this.info?.contact_list?.filter((item) => {
                if (!this.isEmail(item.translate) && !item.is_title) {
                    return item;
                }
            })

            list?.map((item) => {
                item.is_sub_title ? regions.push(item.translate) : numbers.push(item.translate)
            })

            for (let i = 0; i < regions.length; i++) {
                contactList.push([regions[i], numbers[i]]);
            }

            return contactList;
        },
        checkMobile() {
            this.isMobile = window.innerWidth <= 835;
        },
        toggleAboutUs() {
            this.aboutUs = !this.aboutUs
        },
        toggleBusinessAndBrand() {
            this.businessAndBrand = !this.businessAndBrand
        },
        toggleBrand() {
            this.brandSection = !this.brandSection
        },
        togglePartnership() {
            this.partnershipSection = !this.partnershipSection
        },
        toggleNews() {
            this.newsSection = !this.newsSection
        },

    },
    mounted() {
        this.checkMobile()
        window.addEventListener("resize", this.checkMobile);
    }
}
</script>

<style lang="less" scoped>
@import '../../../less/mediaQuery.less';

#footer-top {
    background-color: #161414;
    padding: 0 0 40px;

    @media only screen and(max-width: 836px) {
        height: auto;
    }

    .container {
        display: flex;
        justify-content: space-between;
    }

    .container-links {
        padding-top: 40px;

        .wrapper {
            flex: 1;

            .column {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
            }
        }


        .contact-us-container {
            display: inline-block;
            text-align: left;
            vertical-align: top;
            flex: 0 0 30%;

            .logo {
                width: 224px;
                height: 48px;
            }

            .contact-us-txt {

                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #9e9e9f;
                padding-top: 48px;
                padding-bottom: 19px;
                margin: 0;
            }

            .email-list {
                margin-bottom: 16px;

                .email {
                    font-family: SegoeUI;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    margin: 0;
                    margin-bottom: 8px;

                    .icon-hidden {
                        visibility: hidden;
                    }

                    img {
                        width: 16px;
                        height: 16px;
                        margin-right: 16px;
                    }
                }
            }

            .hp {

                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #9e9e9f;
                display: flex;
                align-items: center;
                margin: 0;

            }

            .hp-container {
                margin-bottom: 40px;

                .wrapper-hp {
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;

                    .icon-hidden {
                        visibility: hidden;
                    }

                    img {
                        width: 16px;
                        height: 16px;
                        margin-right: 16px;
                    }

                    .content {
                        font-family: SegoeUI;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        color: #fff;
                        padding-left: 8px;
                    }
                }
            }

            .social {
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: center;
                margin-bottom: 120px;

                @media only screen and(max-width:836px) {
                    margin-bottom: 0px;
                }

                .follow {
                    margin-bottom: 0;

                    font-size: 14px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #9e9e9f;
                    padding-bottom: 15px;
                    margin-bottom: 0;
                    margin-top: 0;
                }

                .social-logo {
                    .logo {
                        width: 24px;
                        height: 24px;
                        margin-right: 24px;
                    }



                    .logo:nth-child(1) {
                        padding-left: 0;
                    }
                }
            }
        }





        .container-links-contact {
            display: inline-block;

            .list-content {
                display: flex;
                justify-content: center;
                flex-direction: column;
                text-align: left;
                width: 100%;

                a,
                p {

                    font-size: 14px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #9e9e9f;
                    text-decoration: none;
                    padding-bottom: 16px;
                    display: block;

                    &:not(:first-child) {
                        color: #fff;
                    }

                    &:hover,
                    &focus {
                        color: #e03323;
                    }

                }

                p:hover {
                    color: #9e9e9f;
                }

                .last-link {
                    padding-bottom: 0;
                    padding-top: 40px;
                }

                .last-link-contact {
                    padding-bottom: 0;
                    padding-top: 116px;
                }
            }
        }
    }

    .icon-wrapper {
        display: inline-block;

        @media only screen and (min-width: 835px) {
            &:hover {
                img {
                    &:first-child {
                        display: none;
                    }

                    &:last-child {
                        display: block;
                    }
                }
            }

            img {
                &:last-child {
                    display: none;
                }
            }
        }



    }

    .container-links-mobile {
        padding: 0 16px;

        .wrapper-links {
            padding-top: 40px;
            display: flex;
            justify-content: flex-start;
            padding-bottom: 51px;

            .contact-us-container {
                display: flex;
                flex-direction: column;
                align-items: start;

                .logo {
                    width: 224px;
                    height: 48px;

                    @media @mobile-screen {
                        width: 168px;
                        height: auto;
                    }
                }

                .contact-us-txt {

                    font-size: 14px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #9e9e9f;
                    padding-top: 48px;
                    padding-bottom: 19px;
                    margin: 0;
                }

                .email-list {
                    margin-bottom: 16px;

                    .email {
                        font-family: SegoeUI;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        margin: 0;
                        margin-bottom: 8px;

                        .icon-hidden {
                            visibility: hidden;
                        }

                        img {
                            width: 16px;
                            height: 16px;
                            margin-right: 16px;
                        }
                    }
                }

                .hp {

                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #9e9e9f;
                    display: flex;
                    align-items: center;
                    margin: 0;

                }

                .hp-container {
                    margin-bottom: 40px;

                    .wrapper-hp {
                        display: flex;
                        align-items: flex-start;
                        margin-bottom: 8px;

                        .icon-hidden {
                            visibility: hidden;
                        }

                        img {
                            width: 16px;
                            height: 16px;
                            margin-right: 16px;
                        }

                        .content {
                            font-family: SegoeUI;
                            font-size: 14px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.5;
                            letter-spacing: normal;
                            color: #fff;
                            padding-left: 8px;
                        }
                    }
                }

                .social {
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    justify-content: center;
                    margin-bottom: 100px;

                    @media only screen and(max-width:836px) {
                        margin-bottom: 0px;
                    }

                    .follow {
                        margin-bottom: 0;

                        font-size: 14px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        color: #9e9e9f;
                        padding-bottom: 15px;
                        margin-bottom: 0;
                        margin-top: 0;
                    }

                    .social-logo {
                        .logo {
                            width: 24px;
                            height: 24px;
                            margin-right: 24px;
                        }

                        .logo:nth-child(1) {
                            padding-left: 0;
                        }
                    }
                }
            }
        }

        .logos-mobile {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 24px 51px;
            justify-content: start;
            align-items: center;
            width: 100%;
            height: 100%;
            padding-bottom: 40px;

            @media screen and (max-width: 520px) {
                grid-template-columns: repeat(2, 1fr);
            }

            .icon-wrapper {
                max-width: 148px;
                width: 100%;
                height: 28px;
                display: block;

                &:nth-child(1) {
                    width: 115px;
                    height: 28px;
                }

                &:nth-child(2) {
                    width: 148px;
                    height: 28px;
                }

                &:nth-child(3) {
                    width: 115px;
                    height: 28px;
                }

                &:nth-child(4) {
                    width: 127px;
                    height: 28px;
                }

                &:nth-child(5) {
                    width: 121px;
                    height: 28px;
                }

                &:nth-child(6) {
                    width: 138px;
                    height: 28px;
                }

                &:nth-child(7) {
                    width: 87.4px;
                    height: 27.8px;
                }

                &:nth-child(8) {
                    width: 111.2px;
                    height: 27.8px;
                }

                &:nth-child(9) {
                    width: 74.9px;
                    height: 24px;
                }

                &:nth-child(10) {
                    width: 123px;
                    height: 28px;
                }

                &:nth-child(11) {
                    width: 79px;
                    height: 28px;
                }

                &:nth-child(12) {
                    width: 81.4px;
                    height: 27.8px;
                }
            }


            .logo {
                width: 100%;
                height: 100%;
                text-align: left;
                object-fit: contain;
            }
        }

        .txt-left {
            text-align: left;
        }

        .border {
            height: 1px;
            margin-top: 16px;
            background-color: #fff;
            opacity: 0.2;
        }

        .esg-single {
            // margin: 14px 0;
            // padding-bottom: 0 !important;
            width: 100%;
            text-align: left;

            a {
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #9e9e9f;
                text-decoration: none;
            }

            padding-left: 16px;
            padding-top: 16px;

        }

        .container-links-contact {

            .list-content {
                display: flex;
                justify-content: center;
                flex-direction: column;
                text-align: left;
                padding-top: 16px;

                .txt-wrapper {
                    display: flex;
                    justify-content: space-between;
                    cursor: pointer;

                    .title {
                        font-size: 14px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        color: #9e9e9f;
                    }

                    p {
                        font-size: 14px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        color: #fff;
                    }
                }

                .container-dropdown {
                    display: flex;
                    flex-direction: column;

                    a {
                        &:first-child {
                            padding-top: 16px;
                        }

                        font-size: 14px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        text-decoration: none;
                        padding-bottom: 16px;
                        color: #fff;

                        &:last-child {
                            padding-bottom: 0px;
                        }
                    }
                }



                .last-link {
                    padding-bottom: 0;
                    padding-top: 40px;
                }


                .last-link-contact {
                    padding-bottom: 0;
                    padding-top: 116px;
                }


            }
        }
    }

    .logos {
        // display: flex;
        // align-items: center;
        // justify-content: start;

        .wrapper {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            text-align: left;
            gap: 24px 51px;
            justify-items: start;
            align-items: center;
            margin-top: 24px;

            @media screen and (min-width:1024px) and (max-width: 1200px) {
                grid-template-columns: repeat(5, 1fr);
            }

            @media screen and (min-width:836px) and (max-width: 1023px) {
                grid-template-columns: repeat(4, 1fr);
            }
        }

        .logo {
            width: 100%;
            height: 100%;
            // object-fit: contain;
        }

        a {
            display: block;
            height: 100%;
        }
    }


}
</style>